import { ArraySchema, MapSchema } from "@colyseus/schema";

const testRoomState = {

    catchphraseData: {
        gameState: "loading",
        requiredPlayers: 3,
        currentGuess: "",
        currentCatchphrases: new ArraySchema(
            {
                "id": 3,
                "video_url": 'https://big-potato-tv.s3.eu-west-2.amazonaws.com/Catchphrases/720p/Catch+Phrase+Animations+A+Dark+Horse+F5822.mp4',
                "video_url_low": 'https://big-potato-tv.s3.eu-west-2.amazonaws.com/Catchphrases/480p/Catch+Phrase+Animations+A+Dark+Horse+F5822.mp4',
                "answers": ["a dark horse"]
            },
            {
                "id": 85,
                "video_url": "https://big-potato-tv.s3.eu-west-2.amazonaws.com/Catchphrases/Catch+Phrase+Animations+Everythings+Coming+Up+Roses+F5822.mp4",
                "video_url_low": "https://big-potato-tv.s3.eu-west-2.amazonaws.com/Catchphrases/Catch+Phrase+Animations+Everythings+Coming+Up+Roses+F5822.mp4",
                "answers": ["everythings coming up roses"]
            },
            {
                "id": 235,
                "video_url": "https://big-potato-tv.s3.eu-west-2.amazonaws.com/Catchphrases/Catch+Phrase+Animations+Rollerskates+F5822.mp4",
                "video_url_low": "https://big-potato-tv.s3.eu-west-2.amazonaws.com/Catchphrases/Catch+Phrase+Animations+Rollerskates+F5822.mp4",
                "answers": ["rollerskates"]
            },
            {
                "id": 2,
                "video_url": "https://big-potato-tv.s3.eu-west-2.amazonaws.com/Catchphrases/Catch+Phrase+Animations+A+Cheeky+Pint+F5822.mp4",
                "video_url_low": "https://big-potato-tv.s3.eu-west-2.amazonaws.com/Catchphrases/Catch+Phrase+Animations+A+Cheeky+Pint+F5822.mp4",
                "answers": ["a cheeky pint"]
            },
        ),
        currentGridCatchphrase: {
            "id": 147,
            "video_url": "https://big-potato-tv.s3.eu-west-2.amazonaws.com/Catchphrases/Catch+Phrase+Animations+Itsy+Bitsy+Teenie+Weenie+Yellow+Polka+Dot+Bikini+F5822.mp4",
            "video_url_low": "https://big-potato-tv.s3.eu-west-2.amazonaws.com/Catchphrases/Catch+Phrase+Animations+Itsy+Bitsy+Teenie+Weenie+Yellow+Polka+Dot+Bikini+F5822.mp4",
            "answers": ["itsy bitsy teenie weenie yellow polka dot bikini"]
        },
        currentGridReveal: [false, false, false, false], // useing binary to encode what segments should be shown?
        currentGridGuess: "",
        revealCount: 0,
        isGridPhrase: false,
        timerRunning: false,
        timer: 34,
        timerPaused: false,
        round:1,
        isMoneyDrop: false,
        moneyDropAmount: 10,
        moneyDropMax: 500,
        gameStarted: false,
        gameMode: null,
        introSkipped: false,
        hideAnswer: false,
        teams: new ArraySchema(
            {
                id: "A",
                name: "Team A",
                playerIds: ["1", "2", "3", "4"],
                //playerIds: [],
                colour: "#35A8E0",
                score: 500,
                isBuzzedIn: false,
                cooldown: 5,
                cooldownRunning: true,
                moneyDropAmount: 400,
                lockedOut: true,
                isCorrect: false,
                answered: false,
            },
            {
                id: "B",
                name: "Team B",
                playerIds: ["5"],
                //playerIds: [],
                colour: "#FFEC00",
                score: 250,
                isBuzzedIn: false,
                cooldown: -1,
                cooldownRunning: false,
                moneyDropAmount: 342,
                lockedOut: false,
                isCorrect: false,
                answered: false,
            },
            {
                id: "C",
                name: "Team C",
                playerIds: ["6", "7"],
                //playerIds: [],
                colour: "#93C01F",
                score: 0,
                isBuzzedIn: false,
                cooldown: -1,
                cooldownRunning: false,
                moneyDropAmount: 150,
                lockedOut: false,
                isCorrect: false,
                answered: false,
            },
            {
                id: "D",
                name: "Team D",
                playerIds: ["8"],
                //playerIds: [],
                colour: "#8F33D8",
                score: 100,
                isBuzzedIn: false,
                cooldown: -1,
                cooldownRunning: false,
                moneyDropAmount: null,
                lockedOut: false,
                isCorrect: false,
                answered: false,
            }),
    },

    players: new MapSchema(
        {
            "1": {
                name: "WWWWWWWWWW",
                id: "1",
                avatar: 1,
                primaryPlayer: true,
                connected: true,
                catchphraseData: {
                    isPlaying: true,
                    ready: false,
                    isBuzzedIn: false,
                    passed: true,
                    teamIndex: 0,
                    //teamIndex: -1,
                    score: 100,
                    quickestAnswer: 42,
                    showPlayer: true,
                    timerRunning: true,
                    timer: 90,
                },
            },
            "2": {
                name: "Player 2",
                id: "2",
                avatar: 2,
                connected: true,
                catchphraseData: {
                    isPlaying: true,
                    ready: false,
                    isBuzzedIn: false,
                    passed: true,
                    teamIndex: 0,
                    //teamIndex: -1,
                    score: 300,
                    quickestAnswer: 24,
                },
                showPlayer: true,
            },
            "3": {
                name: "Player 3",
                id: "3",
                avatar: 3,
                connected: true,
                catchphraseData: {
                    isPlaying: true,
                    ready: false,
                    isBuzzedIn: false,
                    passed: true,
                    teamIndex: 0,
                    //teamIndex: -1,
                    score: 100,
                    quickestAnswer: 56,
                },
                showPlayer: true,
            },
            "4": {
                name: "Player 4",
                id: "4",
                avatar: 4,
                connected: true,
                catchphraseData: {
                    isPlaying: true,
                    ready: false,
                    isBuzzedIn: false,
                    passed: true,
                    teamIndex: 0,
                    //teamIndex: -1,
                    score: 300,
                    quickestAnswer: 24,
                },
                showPlayer: true,
            },
            "5": {
                name: "Player 5",
                id: "5",
                avatar: 5,
                connected: true,
                catchphraseData: {
                    isPlaying: true,
                    ready: false,
                    isBuzzedIn: false,
                    passed: true,
                    teamIndex: 1,
                    //teamIndex: -1,
                    score: 250,
                    quickestAnswer: 10,
                },
                showPlayer: true,
            },
            "6": {
                name: "Player 6",
                id: "6",
                avatar: 6,
                connected: true,
                catchphraseData: {
                    isPlaying: true,
                    ready: false,
                    isBuzzedIn: false,
                    passed: true,
                    teamIndex: 2,
                    //teamIndex: -1,
                    score: 0,
                    quickestAnswer: Number.MAX_SAFE_INTEGER,
                },
                showPlayer: true,
            },
            "7": {
                name: "Player 7",
                id: "7",
                avatar: 7,
                connected: true,
                catchphraseData: {
                    isPlaying: true,
                    ready: false,
                    isBuzzedIn: false,
                    passed: true,
                    teamIndex: 2,
                    //teamIndex: -1,
                    score: 0,
                    quickestAnswer: Number.MAX_SAFE_INTEGER,
                },
                showPlayer: true,
            },
            "8": {
                name: "Player 8",
                id: "8",
                avatar: 8,
                connected: true,
                catchphraseData: {
                    isPlaying: true,
                    ready: false,
                    isBuzzedIn: false,
                    passed: true,
                    teamIndex: 3,
                    //teamIndex: -1,
                    score: 100,
                    quickestAnswer: 58,
                },
                showPlayer: true,
            }
        },
    ),
}

export {
    testRoomState,
};