import { memo } from 'react';
import OutlineText from './OutlineText';

const MoneyDrop = memo(function MoneyDrop({ styles, show, teams, moneyDropAmount, moneyDropMax }) {
    //console.log("Rendering MoneyDrop", show, teams, moneyDropAmount, moneyDropMax);
    return (
        <div className={`${styles.moneyDrop} ${show && styles.show}`}>
            <div className={styles.bar} style={{ "--fill": `${(moneyDropAmount / moneyDropMax) * 100}%` }}>
                <div className={styles.fill}></div>
                <OutlineText styles={styles} text={Math.round(moneyDropAmount)} className={styles.moneyDropAmount} />
                {
                    teams.map((team) => {
                        return (
                            <div key={`md-${team.id}`}
                                className={`${styles.line} ${team.moneyDropAmount > 0 && styles.show}`}
                                style={{ "--team-colour": team.colour, "--pos": `${(team.moneyDropAmount / moneyDropMax) * 100}%` }}
                            >
                                <OutlineText styles={styles} text={team.moneyDropAmount} />
                            </div>
                        )
                    })
                }
            </div>
            <div className={styles.labels}>
                {
                    teams.map((team) => {
                        return (
                            <div key={`md-${team.id}`}
                                className={`${styles.label} ${team.moneyDropAmount > 0 && styles.show}`}
                                style={{ "--team-colour": team.colour, "--pos": `${(team.moneyDropAmount / moneyDropMax) * 100}%` }}
                            >
                                <OutlineText styles={styles} text={team.name} />
                            </div>
                        )
                    })
                }
            </div>
        </div>
    )
});

export default MoneyDrop;