import { memo } from 'react';
import OutlineText from './OutlineText';
import mutedIcon from "images/Host/lobby_muted.png";
import unmutedIcon from "images/Host/lobby_unmuted.png";
import fullscreenIcon from "images/Host/lobby_fullscreen.png";
import helpIcon from "images/Host/lobby_help.png";
import QRCode from 'qrcode.react';
const RoomDetails = memo(function RoomDetails({ styles, url, displayUrl, muted, roomId, fullscreenAvailable, onToggleMute, onToggleFullScreen, onToggleMenu }) {
    //console.log("Rendering RoomDetails", url, muted, roomId, fullscreenAvailable);
    return (
        <div className={styles.roomCode}>
            <div className={styles.textBox}>
                <OutlineText styles={styles} text={displayUrl} />
                <OutlineText styles={styles} text={(<>Code: <span className={`${styles.code}`}>{roomId}</span></>)} />

                <div className={styles.iconsBox}>
                    <div className={styles.muteToggle} onClick={() => onToggleMute()}>
                        <img src={muted ? mutedIcon : unmutedIcon} className={styles.muteIcon} alt="mute" />
                    </div>
                    {
                        fullscreenAvailable ?
                            <div className={styles.muteToggle} onClick={() => onToggleFullScreen()}>
                                <img src={fullscreenIcon} className={styles.muteIcon} alt="fullscreen" />
                            </div>
                            :
                            null
                    }
                    <div className={styles.muteToggle} onClick={() => onToggleMenu()}>
                        <img src={helpIcon} className={styles.muteIcon} alt="help" />
                    </div>
                </div>
            </div>
            <div className={styles.qrCodeBox}>
                <QRCode
                    className={styles.qrCode}
                    value={`${url}/play/?qrCode=${roomId}`}
                    bgColor="rgba(0,0,0,0)"
                />
            </div>
        </div>
    )
});

export default RoomDetails;