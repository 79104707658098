import React, { Component } from 'react';
import Lottie from 'react-lottie';

import getAvatarById from "constants/avatars";
import styles from "components/PlayerStyles.module.scss";

const animations = [
    "bounce",
    "rubberBand",
    "shakeX",
    "shakeY",
    "headShake",
    "swing",
    "tada",
    "wobble",
    "jello",
    "heartBeat",
];

export default class Player extends Component {

    constructor(props) {
        super(props);
        this.state = {

        }
    }

    componentDidMount() {
        //find any element with a tabindex and set it to -1, this is a hack to prevent the potato from being focused (react lottie has this hard coded))
        const elements = document.querySelectorAll('[tabindex]');
        elements.forEach(element => {
            element.setAttribute('tabindex', '-1');
        });
    }

    animateCSS = (element, animation, prefix = 'animate__') =>
        // We create a Promise and return it
        new Promise((resolve, reject) => {
            const animationName = `${prefix}${animation}`;
            element.classList.add(`${prefix}animated`, animationName);

            // When the animation ends, we clean the classes and resolve the Promise
            function handleAnimationEnd(event) {
                event.stopPropagation();
                element.classList.remove(`${prefix}animated`, animationName);
                resolve('Animation ended');
            }

            element.addEventListener('animationend', handleAnimationEnd, { once: true });
        });

    doAnimation = (player) => {
        let animation = this.getRandomElement(animations);
        this.props.room?.send("animate_potato", { animation: animation, });
        const potato = document.getElementById(`potato-${player.id}`);
        if (potato) {
            this.animateCSS(potato, animation);
        }
    }

    getRandomElement(array) {
        return array[Math.random() * array.length >> 0];
    }

    render() {
        const catchphraseData = this.props.player.catchphraseData;
        const player = this.props.player;
        const team = this.props.team;
        const lockedOut = this.props.lockedOut;
        return (
            <div id={`player-${player.id}`}
                className={`${styles.player} ${styles.show} ${catchphraseData.isBuzzedIn && styles.isBuzzedIn} ${lockedOut && styles.lockedOut} ${this.props.large && styles.large}`}
                style={{ "--team-colour": team ? team.colour : "grey" }}
                onClick={() => this.doAnimation(player)}
                tabindex='-1'
            >
                <div id={`potato-${player.id}`} className={`${styles.potato}`}>
                    <Lottie
                        options={getAvatarById(player.avatar).idleAnim}
                        width="100%"
                        height="100%"
                        isClickToPauseDisabled={true}
                    />
                <div className={`${styles.pass} ${catchphraseData.passed && styles.show}`}>PASS</div>
                </div>
                <div className={`${styles.textSection}`}>
                    <div className={styles.buzzer}>
                        <div className={styles.line}></div>
                    </div>
                    <div className={`${styles.name}`}>{player.connected ? player.name : "Not connected!"}</div>
                </div>
            </div>
        )
    }
}