import React, { Component } from 'react';
import { Route } from 'react-router';
import * as Sentry from "@sentry/react";

import * as Colyseus from "colyseus.js";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faDeleteLeft, faPaperPlane } from '@fortawesome/free-solid-svg-icons'
import Trophy from "../images/Trophy.png";
//import Lottie from 'react-lottie';
import nosleep from 'nosleep.js';
import "animate.css";

import Loading from "components/Loading";
import ErrorModal from './Utility/ErrorModal';
import logo from "images/logo.png";
import styles from 'components/ClientStyles.module.scss';
import { testRoomState } from "constants/testData";
import { GameStates, CatchphraseServerInMessages, CatchphraseServerOutMessages } from "constants/constants"
import Player from "components/Player";
import ClientMenuIcon from './ClientMenuIcon';

var noSleep = new nosleep();
//var supportsVibrate = "vibrate" in navigator;
const gameId = "catchphrase";

export class Client extends Component {
    static displayName = Client.name;

    constructor(props) {
        super(props);

        this.client = new Colyseus.Client(process.env.REACT_APP_GAME_SERVER_URL);
        this.state = {
            roomId: 0,
            room: null,
            myId: null,
            myId: "1", //debug
            roomState: null,
            roomState: testRoomState, //Debug
            redirect: null,
            redirectURL: "",
            isPaused: false,
            selectedLetter: 0,
            guess: "",
            //connected: true, //debug

            tickedSkipTutorial: true,
            voteSkipTutorial: false,
        };
        this.locationCheckInterval = null;
        //this.inputRef = React.createRef([]);
    }

    componentDidMount() {
        this.setTags();
        setTimeout(() => {
            this.doReconnect();
        }, 1500);

        document.addEventListener('click', function enableNoSleep() {
            document.removeEventListener('click', enableNoSleep, false);
            noSleep.enable();
        }, false);

        //Dev only
        if (window.location.hostname === "localhost") {
            setInterval(() => {
                var iframes = document.querySelectorAll('iframe');
                if (iframes.length > 0) console.log("Developer: ", "react iframe found, trying to remove it");
                for (var i = 0; i < iframes.length; i++) {
                    iframes[i].parentNode.removeChild(iframes[i]);
                }
            }, 1000);
        }
    }

    setTags() {
        const token = this.getQueryStringValue('token');
        Sentry.setTag('isPlayer', true);

        if (token) {
            const [roomId, reconnectToken] = token.split(':');
            Sentry.setTag('roomId', roomId);
            Sentry.setTag('reconnectToken', reconnectToken);
        }
    }

    getQueryStringValue(key) {
        return decodeURIComponent(window.location.search.replace(new RegExp("^(?:.*[&\\?]" + encodeURIComponent(key).replace(/[.+*]/g, "\\$&") + "(?:\\=([^&]*))?)?.*$", "i"), "$1"));
    }

    // checking to ensure player is in the right place e.g. in the correct game
    startLocationChecks() {
        this.state.room.send("location_check", { gameId, });
        this.locationCheckInterval = setInterval(() => {
            this.state.room.send("location_check", { gameId, });
        }, 10000);
    }

    skipTutorial() {
        this.state.room.send("vote_skip");
        this.setState({ voteSkipTutorial: true });
    }

    goToLobby() {
        this.setState({ redirectURL: `${this.getRedirectURL()}/?token=${this.state.reconnectionToken}` });
        this.state.room.leave(false);
        if (this.locationCheckInterval) clearInterval(this.locationCheckInterval);
        console.log("going to lobby", this.state.redirectURL);
    }

    signalGoToLobby = () => {
        this.state.room.send("change_game", {});
    }

    getRedirectURL(display = false) {
        let url = display ? process.env.REACT_APP_GAME_CITY_URL_DISPLAY : process.env.REACT_APP_GAME_CITY_URL;
        if (this.state.room) {
            if (this.state.room.name !== "game_city_room") {
                url = display ? process.env.REACT_APP_HOME_URL_DISPLAY : process.env.REACT_APP_HOME_URL;
            }
        }
        return url;
    }

    updateToken(token) {
        var url = new URL(window.location.href);

        try {
            window.history.replaceState(null, null, (url.pathname) + (`?token=${token}`));
        } catch (e) {
            console.warn(e)
        }
    }

    formatTimerDisplay(player) {
        if (!player.catchphraseData) return "NaN";
        const totalSeconds = player.catchphraseData.timer
        const minuets = Math.floor(totalSeconds / 60);
        const seconds = totalSeconds % 60;
        return `${minuets}:${String(seconds).padStart(2, '0')}`;
    }

    getCurrentCatchphrase() {
        const catchphraseData = this.state.roomState?.catchphraseData;
        if (!catchphraseData) return null;
        if (catchphraseData.isGridPhrase)
            return catchphraseData.currentGridCatchphrase;

        const revealCount = catchphraseData.revealCount;
        return catchphraseData.currentCatchphrases.at(revealCount);
    }

    getCurrentViewState(player) {
        const catchphraseData = this.state.roomState?.catchphraseData;
        const viewState = catchphraseData?.gameStarted && catchphraseData.gameMode === "teams" && player.catchphraseData?.teamIndex === -1 ? GameStates.SelectingTeams : catchphraseData?.gameState;
        return viewState;
    }

    selectLetter(index) {
        console.log("selecting letter", index,);
        var guessArray = this.state.guess.split("");
        if (guessArray.length < index) {
            //fill string with spaces up to index
            for (var i = guessArray.length; i < index; i++) {
                guessArray.push(" ");
            }
        }

        this.setState({ selectedLetter: index, guess: guessArray.join("") });
    }

    handleDeviceKeypress(e, answer, areYouBuzzedIn) {
        if (!areYouBuzzedIn) return;
        console.log("key pressed", e.target);
        e.preventDefault();
        e.stopPropagation();
        const value = e.target.value;
        const letter = value[value.length - 1];
        console.log(value);
        if (e.keyCode === 13) {
            const answerArrayCount = answer?.split("").filter(l => l !== " ").length;
            const guessCount = this.state.guess.split("").filter(l => l !== " ").length;
            if (answerArrayCount !== guessCount) return;
            this.signalSubmitGuess();
            return;
        }

        if (value.length === 0) {
            this.backspace();
            return;
        }

        //if(e.key.length > 1) return;

        if ((/[a-zA-Z]/g).test(letter)) {
            this.typeLetter(letter);
            return;
        }
    }

    typeLetter(letter) {
        const catchphrase = this.getCurrentCatchphrase();

        var selectedLetterIndex = this.state.selectedLetter
        var guessArray = this.state.guess.split("");
        const answerArray = catchphrase.answers[0].split("");

        guessArray[this.state.selectedLetter] = letter;

        if (selectedLetterIndex < answerArray.length - 1) {
            selectedLetterIndex++;
            const isNextLetterSpace = answerArray[selectedLetterIndex] === " ";
            if (isNextLetterSpace) {
                guessArray[selectedLetterIndex] = " ";
                selectedLetterIndex++;
            }
        }
        //this.inputRef.current?.focus();
        this.setState({ guess: guessArray.join(""), selectedLetter: selectedLetterIndex });
    }

    backspace() {
        const catchphrase = this.getCurrentCatchphrase();

        var selectedLetterIndex = this.state.selectedLetter
        var guessArray = this.state.guess.split("");
        const answerArray = catchphrase.answers[0].split("");

        if (selectedLetterIndex === 0) {
            guessArray[0] = " ";
        }
        else {
            const currentLetter = guessArray[selectedLetterIndex];
            if (!currentLetter || !(/[a-zA-Z]/g).test(guessArray[selectedLetterIndex])) selectedLetterIndex--;
            guessArray[selectedLetterIndex] = " ";

            const isNextLetterSpace = answerArray[selectedLetterIndex] === " ";
            if (isNextLetterSpace) {
                selectedLetterIndex--;
                guessArray[selectedLetterIndex] = " ";
            }
        }

        this.setState({ guess: guessArray.join(""), selectedLetter: selectedLetterIndex });
    }

    resetGuess(currentGuess = "") {
        console.log("resetting guess", currentGuess);
        const currentCatchphrase = this.getCurrentCatchphrase();

        let selectedLetter = 0;
        if (currentCatchphrase) {
            const answerArray = currentCatchphrase.answers[0].split("")
            selectedLetter = answerArray.findIndex((letter, index) => letter !== currentGuess[index]);
        }

        this.setState({
            guess: currentGuess, selectedLetter, buzzingIn: false
        });
    }

    signalbuzzIn() {
        console.log("buzzing in")
        this.state.room?.send(CatchphraseServerInMessages.PlayerBuzzedIn, {});
        //this.inputRef.current.focus();
        this.setState({ buzzingIn: true });
    }

    signalSelectedTeam(index) {
        console.log("selected team", index);
        const me = this.state.roomState.players[this.state.myId]
        this.state.room?.send(CatchphraseServerInMessages.TeamSelected, { teamIndex: index, removeFromTeamIndex: me.catchphraseData?.teamIndex })
    }

    signalSubmitGuess() {
        console.log("submitting guess", this.state.guess)
        this.state.room?.send(CatchphraseServerInMessages.PlayerGuessed, { guess: this.state.guess })
    }

    signalStartGame = (confirm = false) => {
        const players = this.state.roomState?.players ? Array.from(this.state.roomState.players.values()) : null;
        let allConnected = players.every(p => p.connected);
        console.log("allConnected", allConnected);
        if (!confirm && !allConnected) {
            this.setState({ showStartWarning: true });
        } else {
            this.setState({ showStartWarning: false })
            console.log("sending start game")
            this.state.room?.send("begin_game", { skipTutorial: this.state.tickedSkipTutorial, });
        }
    }

    signalStartSolos = () => {
        this.state.room?.send(CatchphraseServerInMessages.FinishGameSelection, { gameType: "solos" });
    }

    signalStartTeams = () => {
        this.state.room?.send(CatchphraseServerInMessages.FinishGameSelection, { gameType: "teams" });
    }

    signalSkipIntro = () => {
        this.state.room.send(CatchphraseServerInMessages.SkipRoundIntro, {});
    }

    signalPlayAgain = (confirm = false) => {
        const players = this.state.roomState?.players ? Array.from(this.state.roomState.players.values()) : null;
        let allConnected = players.every(p => p.connected);

        if (!confirm && !allConnected) {
            this.setState({ showStartWarning: true });
        } else {
            this.setState({ showStartWarning: false })
            this.state.room?.send(CatchphraseServerInMessages.PlayAgain, {});
        }
    }

    signalPass = () => {
        this.state.room?.send(CatchphraseServerInMessages.Pass, {});
    }

    signalFinishTeamsSelection = () => {
        this.state.room?.send(CatchphraseServerInMessages.FinishTeamsSelection, {});
    }

    toggleSkipTutorial = (e) => {
        this.setState({ tickedSkipTutorial: e.target.checked });
    }

    closeStartWarning = () => {
        this.setState({ showStartWarning: false });
    }

    confirmStartGame = () => {
        this.signalStartGame(true);
    }

    confirmPlayAgain = () => {
        this.signalPlayAgain(true);
    }

    doReconnect = () => {
        // fetch room and session id from query params
        //const sessionId = this.getQueryStringValue("sessionId");
        //const roomId = this.getQueryStringValue("roomId");
        const token = this.getQueryStringValue("token");
        // start reconnecting player to game
        this.client.reconnect(token).then(room => {
            console.log(room.sessionId, "joined", room.name);
            this.setState({ room: room, roomId: room.id, myId: room.sessionId, reconnectionToken: room.reconnectionToken });
            this.updateToken(room.reconnectionToken);
            room.send("update_player_token", { reconnectionToken: room.reconnectionToken });
            console.log("room", room);

            room.onStateChange.once((roomState) => {
                console.log("this is the first room state!", roomState);
                const player = roomState.players[room.sessionId];
                Sentry.setUser({ id: player.uniqueId });
                if (!player) window.location = this.getRedirectURL();
                this.setState({ roomState: roomState, connected: true });
                roomState.host.listen("connected", (value) => {
                    this.setState({ hostConnected: value });
                });

                this.startLocationChecks();
            });

            room.onStateChange((state) => {
                console.log("onStateChange", state);
                this.setState({ roomState: state });
            });

            room.onMessage("show_tutorial", (message) => {
                console.log("show_tutorial", "received on", room.name, message);
                this.setState({ voteSkipTutorial: false });
            });

            room.onMessage("end_tutorial", (message) => {
                console.log("end_tutorial", "received on", room.name, message);
            });

            room.onMessage("game_starting", (message) => {
                console.log("game_starting", "received on", room.name, message);
                if (message.gameId !== gameId) {
                    this.goToLobby();
                }
            });

            room.onMessage(CatchphraseServerOutMessages.RevealAnswer, (message) => {
                console.log("reveal_answer", "received on", room.name, message);
                this.resetGuess();
            });

            room.onMessage(CatchphraseServerOutMessages.StartPhrase, (message) => {
                console.log("start_round", "received on", room.name, message);
                this.resetGuess();
            });

            room.onMessage(CatchphraseServerOutMessages.PlayerGuessed, (message) => {
                console.log("player_guessed", "received on", room.name, message);
                if (message.playerId === this.state.myId) this.resetGuess(message.validGuess);
            });

            room.onMessage(CatchphraseServerOutMessages.BuzzerTimedOut, (message) => {
                console.log("buzzer_timed_out", "received on", room.name, message);
                if (message.playerId === this.state.myId) this.resetGuess();
            });

            room.onMessage(CatchphraseServerOutMessages.PlayerBuzzedIn, (message) => {
                console.log("player_buzzed_in", "received on", room.name, message);
                //if (message.playerId === this.state.myId) this.inputRef.current.focus();
            });

            room.onMessage(CatchphraseServerOutMessages.CancelBuzzIn, (message) => {
                console.log("cancel_buzz_in", "received on", room.name, message);
                this.setState({ buzzingIn: false });
            });

            room.onMessage("host_joined_lobby", (message) => {
                console.log("host_joined_lobby", "received on", room.name, message);
                this.goToLobby();
            });

            room.onMessage("change_game", (message) => {
                console.log("change_game", "received on", room.name, message);
                this.goToLobby();
            });

            room.onError((code, message) => {
                console.log(this.client.id, "couldn't join", room.name);
            });

            room.onLeave((code) => {
                console.log(this.client.id, "left", room.name);
                this.setState({ connected: false });
                if (!this.state.redirectURL) {
                    if (code === 4050) {
                        this.setState({ redirect: true, redirectURL: `${this.getRedirectURL()}/` });
                        if (this.locationCheckInterval) clearInterval(this.locationCheckInterval);
                    } else {
                        this.doReconnect();
                    }
                } else {
                    setTimeout(() => {
                        this.setState({ redirect: true, });
                    }, 1500);
                }
            });
        }).catch(e => {
            console.log("JOIN ERROR", e);
            this.setState({ redirect: true, connected: false, redirectURL: `${this.getRedirectURL()}/`, hasErrored: true });
            if (this.locationCheckInterval) clearInterval(this.locationCheckInterval);
        });
    }

    renderAnswerView(viewState, player, team, players, showBuzzedInPlayer) {
        const areYouBuzzedIn = player.catchphraseData?.isBuzzedIn
        const catchphrase = this.getCurrentCatchphrase();
        const answer = catchphrase?.answers[0];
        const buzzedInPlayer = showBuzzedInPlayer && players.find(p => p.catchphraseData?.isBuzzedIn);
        const lockedOut = this.state.buzzingIn || !team || team.lockedOut || team.answered || team.cooldownRunning || player.catchphraseData?.passed;
        return <>
            <div className={`${styles.timer} ${viewState === GameStates.Playing && areYouBuzzedIn && styles.show}`} >{this.formatTimerDisplay(player)}</div>
            {/*<input ref={this.inputRef} className={styles.hiddenInput} type="text" onKeyDown={(e) => this.handleDeviceKeypress(e, answer, areYouBuzzedIn)} />*/}
            <button className={`${styles.pass} ${viewState === GameStates.Playing && !areYouBuzzedIn && !lockedOut && styles.show}`} onClick={this.signalPass}>{this.renderTextDiv("PASS")}</button>
            {this.renderCatchphraseLetters(viewState, catchphrase, areYouBuzzedIn)}
            {this.renderBuzzer(viewState, team, areYouBuzzedIn, lockedOut, buzzedInPlayer)}
            {this.renderKeyboard(viewState, areYouBuzzedIn, answer)}
        </>
    }

    renderRevealAnswerSection(viewState, catchphraseData, teams) {
        const catchphrase = this.getCurrentCatchphrase();
        const wasCorrect = teams.some(t => t.isCorrect);
        const shouldRevealAnswer = wasCorrect || !catchphraseData.isGridPhrase || catchphraseData.revealCount === 4;
        const answer = shouldRevealAnswer ? catchphrase?.answers[0] : "???";
        return (
            <div className={`${styles.revealAnswerSection} ${viewState === GameStates.RevealAnswer && !catchphraseData.hideAnswer && styles.show}`} >
                {this.renderTextDiv("The answer was:", styles.instruction)}
                {this.renderTextDiv(answer, styles.answer)}
            </div>
        )
    }

    renderRoundSummarySection(viewState, catchphraseData, team) {
        return (
            <div className={`${styles.roundSummarySection} ${viewState === GameStates.RoundSummary && styles.show}`} >
                {this.renderTextDiv(team?.playerIds?.length === 0 ? "Your Score" : "Team Score", styles.instruction)}
                {this.renderTextDiv(team?.score, styles.score)}
                {this.renderTextDiv(`Round ${catchphraseData.round} over`, styles.instruction)}
            </div>
        )
    }

    renderCatchphraseLetters(viewState, catchphrase, areYouBuzzedIn) {
        if (!catchphrase) return;
        //const letterArray = catchphrase.answer.split("");
        const maxLettersInRow = 12;
        //create rows of words based on the catchphrase.answer so that no row is longer than maxLettersInRow
        const answer = catchphrase.answers[0];
        const words = answer.split(" ");
        const rows = [];
        let currentRow = [];
        let currentRowLength = 0;
        words.forEach(word => {
            if (currentRowLength + word.length > maxLettersInRow) {
                rows.push(currentRow.join(" "));
                currentRow = [];
                currentRowLength = 0;
            }
            currentRow.push(word);
            currentRowLength += word.length + 1;
        });
        rows.push(currentRow.join(" "));

        let i = -2;
        //render grid of letter boxes based on the rows
        return <>
            <div className={`${styles.catchphraseGrid} ${viewState === GameStates.Playing && styles.show}`}>
                {this.renderPhraseRows(rows, i, areYouBuzzedIn, answer)}
            </div>
        </>
    }

    renderPhraseRows(rows, i, areYouBuzzedIn, answer) {
        return rows.map((row, rowIndex) => {
            const letterArray = row.split("");
            i++;
            return <div key={"row-" + rowIndex} className={styles.row}>
                {
                    letterArray.map((letter, letterIndex) => {
                        i++;
                        const index = i; //have to de-scope i to use it in the onClick function
                        return letter === " " ? <div key={rowIndex + "-" + letterIndex} className={styles.space} ></div>
                            : <button key={rowIndex + "-" + letterIndex}
                                onClick={() => this.selectLetter(index)}
                                className={`${styles.letter} ${i === this.state.selectedLetter && styles.selected}`}
                                disabled={!areYouBuzzedIn}
                                type="text" maxLength="1"
                            //value={this.state.guess[i]?.toUpperCase()}
                            >
                                {this.state.guess[i]?.toUpperCase()}
                            </button>
                    })
                }
            </div>
        })
    }

    renderBuzzer(viewState, team, areYouBuzzedIn, lockedOut, buzzedInPlayer) {
        //const buzzedInTeams = teams.filter(t => t.isBuzzedIn);
        //const preventMultipleBuzzin = buzzedInTeams.length > 0 && !catchphraseData?.isMoneyDrop
        return (
            <div className={`${styles.buzzer} ${viewState === GameStates.Playing && !areYouBuzzedIn && styles.show} ${team?.isBuzzedIn && styles.teamBuzzedIn}`}>
                {/*<div className={styles.buzzerText}>BUZZ IN</div>*/}
                <div className={styles.buzzerOuter}>
                    <button onClick={() => this.signalbuzzIn()} disabled={this.state.buzzingIn || lockedOut}>
                        <div className={styles.lockedMessage}>LOCKED</div>
                        <div className={`${styles.cooldown} ${team?.cooldownRunning && team?.cooldown && styles.show}`}>{Math.max(0, team?.cooldown)}s</div>
                    </button>
                </div>
                <div className={`${styles.buzzedInPlayer} ${buzzedInPlayer && styles.show}`}>
                    {`${buzzedInPlayer?.name} has buzzed in`}
                </div>
            </div>
        )
    }

    renderKeyboard(viewState, areYouBuzzedIn, answer) {
        const row1 = "qwertyuiop".split("");
        const row2 = "asdfghjkl".split("");
        const row3 = "zxcvbnm".split("");
        const answerArrayCount = answer?.split("").filter(l => l !== " ").length;
        const guessCount = this.state.guess.split("").filter(l => l !== " ").length;

        return <>
            <div className={`${styles.keyboard} ${viewState === GameStates.Playing && areYouBuzzedIn && styles.show}`} >
                <div className={styles.buttonHolder} >
                    <button className={`${styles.button} ${styles.green}`} onClick={() => this.signalSubmitGuess()} disabled={answerArrayCount !== guessCount} >SUBMIT</button>
                </div>
                <div className={`${styles.buttonHolder} ${styles.left}`} >
                    <button className={`${styles.button}`} onClick={() => this.signalSubmitGuess()} >GIVE UP</button>
                </div>
                <div className={styles.row}>
                    {
                        row1.map((letter, index) => {
                            return <button className={`${styles.key}`} key={index} onClick={() => this.typeLetter(letter)}>{letter.toUpperCase()}</button>
                        })
                    }
                </div>
                <div className={styles.row}>
                    {
                        row2.map((letter, index) => {
                            return <button className={`${styles.key}`} key={index} onClick={() => this.typeLetter(letter)}>{letter.toUpperCase()}</button>
                        })
                    }
                </div>
                <div className={styles.row}>
                    <div className={`${styles.key} ${styles.wide} ${styles.gap}`}>
                    </div>
                    {
                        row3.map((letter, index) => {
                            return <button className={`${styles.key}`} key={index} onClick={() => this.typeLetter(letter)}>{letter.toUpperCase()}</button>
                        })
                    }
                    <button className={`${styles.key} ${styles.wide}`} onClick={() => this.backspace()} >
                        <FontAwesomeIcon icon={faDeleteLeft} />
                        {/*DEL*/}
                    </button>
                </div>
            </div>
        </>
    }

    renderSelectingTeamsView(viewState, player, players, teams) {
        return (
            <div className={`${styles.teamSelect} ${viewState === GameStates.SelectingTeams && styles.show}`} >
                {this.renderTextDiv(<>WHICH TEAM WOULD<br />YOU LIKE TO BE ON?</>, styles.instruction)}
                {
                    teams.map((team, index) => {
                        return <div className={`${styles.teamSelectButtonWrapper}`} key={"team-" + index}>
                            <button
                                className={`${styles.teamSelectButton} ${index === player.catchphraseData?.teamIndex && styles.selected}`}
                                onClick={() => this.signalSelectedTeam(index)}
                                style={{ "--team-colour": team.colour }}
                            >
                                {this.renderTextDiv(team.name)}
                            </button>
                        </div>
                    })
                }
                <button className={`${styles.mainButton} ${styles.smaller} ${this.validateTeams(players, teams) && player?.primaryPlayer && styles.show}`} onClick={this.signalFinishTeamsSelection}>
                    {this.renderTextDiv("Confirm Teams")}
                </button>
            </div>
        )
    }

    renderRoundIntroView(viewState, catchphraseData, player) {
        const round = catchphraseData.round;
        const subTitle = round === 1 ? "FIRST ROUND" : round === 2 ? "SECOND ROUND" : "FINAL ROUND";
        const title = round === 1 ? <>POINT<br />PURSUIT</> : round === 2 ? <>QUICK<br />FIRE</> : <>CRUNCH<br />TIME</>;
        return (
            <div className={`${styles.roundIntroSection} ${viewState === GameStates.RoundIntro && styles.show}`} >
                <div className={styles.titles}>
                    {this.renderTextDiv(subTitle, styles.subTitle)}
                    {this.renderTextDiv(title, styles.title)}
                </div>
                {this.renderTextDiv("Get ready to play!", styles.instruction)}
                <button className={`${styles.mainButton} ${styles.smaller} ${player?.primaryPlayer && !this.state.roomState.catchphraseData?.introSkipped && styles.show}`} onClick={this.signalSkipIntro}>
                    {this.renderTextDiv("Skip Intro")}
                </button>
            </div>
        )
    }

    validateTeams(players, teams) {
        const allPlayersInTeams = players.filter(p => p.connected).every(p => p.catchphraseData?.teamIndex > -1);
        const teamsWithPlayers = teams.filter(t => t.playerIds.length > 0);
        return allPlayersInTeams && teamsWithPlayers.length >= 2;
    }

    renderStartButtons(viewState, player) {
        return (
            <div className={`${styles.startButtonSection} ${player?.primaryPlayer && viewState === GameStates.MainMenu && styles.show}`}>
                <button className={`${styles.mainButton}`} onClick={() => this.signalStartGame(false)}>
                    {this.renderTextDiv("Start Game")}
                </button>
                <button className={`${styles.mainButton} ${styles.smaller} ${styles.alt}`} onClick={this.signalGoToLobby}>
                    {this.renderTextDiv("Return To Lobby")}
                </button>
                {/*<div className={styles.toggle}>*/}
                {/*    <input className={styles.checkbox} type="checkbox" id="checkbox" name="checkbox" onChange={this.toggleSkipTutorial} />*/}
                {/*    <label htmlFor="checkbox" className={styles.text}>Skip Tutorial*/}
                {/*        <div className={styles.face}>Skip Tutorial</div>*/}
                {/*    </label>*/}
                {/*</div>*/}
            </div>
        )
    }

    renderTutorial(viewState) {
        return (
            <div className={`${styles.tutorialSection} ${viewState === GameStates.Tutorial && !this.state.voteSkipTutorial && styles.show}`}>
                <button className={`${styles.mainButton}`} onClick={this.skipTutorial}>
                    {this.renderTextDiv("Skip Tutorial")}
                </button>
            </div>
        )
    }

    renderGameSelectButtons(viewState, player, players) {
        return (
            <div className={`${styles.gameSelectSection} ${(player?.primaryPlayer && viewState === GameStates.GameSelect) && styles.show}`}>
                {this.renderTextDiv(<>Choose whether you would prefer<br />to team up or go it alone?</>, styles.instruction)}
                <button className={`${styles.mainButton}`} onClick={this.signalStartTeams} disabled={players.length < 2}>
                    {this.renderTextDiv("Play in Teams")}
                </button>
                <button className={`${styles.mainButton} ${styles.smaller} ${styles.alt}`} onClick={this.signalStartSolos}>
                    {this.renderTextDiv("Play Individually")}
                </button>
            </div>
        )
    }

    renderTextDiv(text, additionalStyles) {
        return <>
            <div className={`${styles.text} ${additionalStyles}`}>{text}
                <div className={styles.face}>{text} </div>
            </div>
        </>
    }

    renderPlayerSection(viewState, player, team) {
        const centerPlayer = [GameStates.Loading, GameStates.MainMenu, GameStates.GameSelect].includes(viewState);
        const lockedOut = this.state.buzzingIn || team?.lockedOut || team?.answered;
        return (
            <div className={`${styles.playerSection} ${centerPlayer && styles.center}`}>
                {
                    player && player.id &&
                    <Player player={player} room={this.state.room} team={team} lockedOut={lockedOut} large={centerPlayer} />
                }
            </div>
        )
    }

    renderEndGameSection(viewState, player, team) {
        const highestScore = this.state.roomState.catchphraseData.teams.reduce((max, t) => t.score > max ? t.score : max, 0);
        const winners = this.state.roomState.catchphraseData.teams.filter(t => t.score === highestScore);
        const areYouAWinner = winners.some(t => t.playerIds.find(id => id === this.state.myId));
        const comment = areYouAWinner ? "WELL DONE!" : "OH DEAR!";
        const winnerMessage = areYouAWinner ?
            team?.playerIds.length > 1 ? <>YOUR TEAM<br />HAS WON</> : <>YOU ARE<br />THE WINNER</> :
            team?.playerIds.length === 1 ? <>YOUR TEAM<br />HAS LOST</> : <>YOU HAVE<br />LOST</>;

        return (
            <div className={`${styles.endGameSection} ${viewState === GameStates.EndGame && styles.show}`}>
                {this.renderTextDiv(comment, styles.comment)}
                {this.renderTextDiv(winnerMessage, styles.winnerMessage)}
                <div className={`${styles.buttons} ${player?.primaryPlayer && styles.show}`}>
                    <button className={`${styles.mainButton}`} onClick={this.signalPlayAgain}>
                        {this.renderTextDiv("Play Again")}
                    </button>
                    <button className={`${styles.mainButton} ${styles.smaller} ${styles.alt}`} onClick={this.signalGoToLobby}>
                        {this.renderTextDiv("Return to Lobby")}
                    </button>
                </div>
                <img src={Trophy} className={`${styles.trophy} ${areYouAWinner && styles.show}`} alt="trophy" />
            </div>
        )
    }

    renderStartWarning() {
        return (
            this.state.showStartWarning &&
            <ErrorModal
                title={"Are you ready to play?"}
                styles={"d-flex"}
                message={"It looks like all the players might not be connected to the game, are you sure you would like to start?"}
                callback={this.closeStartWarning}
                callbackText={"No"}
                callback2={this.props.gameState === GameStates.EndGame ? this.confirmPlayAgain : this.confirmStartGame}
                callbackText2={"Yes"}
            />
        )
    }

    renderLogoSection(viewState) {
        const logoShowStates = [GameStates.MainMenu, GameStates.GameSelect];
        return (
            <div className={`${styles.logoSection} ${logoShowStates.includes(viewState) && styles.show}`}>
                <img src={logo} className={styles.logo} alt="logo" />
            </div>
        )
    }

    render() {
        //#region Handle Redirect
        if (this.state.redirectURL && !(process.env.NODE_ENV === "development" && this.state.hasErrored)) {
            return (
                <React.Fragment>
                    <div id="gameContainer" className={styles.gameContainer}>
                        <div className={styles.loadingContainer}>
                            <Loading loadingText={"Sending you to the lobby!"} />
                        </div>
                    </div>
                    <div style={{ opacity: 0 }}>
                        <Route path="/" render={() => (window.location = this.state.redirectURL)} />
                    </div>'
                </React.Fragment>
            )
        }
        //#endregion

        const catchphraseData = this.state.roomState?.catchphraseData;
        const player = this.state.roomState?.players.get(this.state.myId);
        const viewState = this.getCurrentViewState(player);
        const teams = catchphraseData?.teams;
        const team = teams && player ? teams[player.catchphraseData.teamIndex] : null;
        const players = this.state.roomState?.players ? Array.from(this.state.roomState.players.values()) : null;
        return <>
            {
                catchphraseData && player && player.catchphraseData && this.state.connected ?
                    <div id="clientContainer" className={`${styles.clientContainer}`} style={{ "--player-colour": team ? team.colour : "grey" }} >
                        {player?.primaryPlayer && <ClientMenuIcon room={this.state.room} />}
                        {this.renderLogoSection(viewState)}
                        {this.renderStartButtons(viewState, player)}
                        {this.renderTutorial(viewState)}
                        {this.renderGameSelectButtons(viewState, player, players)}
                        {this.renderSelectingTeamsView(viewState, player, players, teams)}
                        {this.renderRoundIntroView(viewState, catchphraseData, player)}
                        {this.renderPlayerSection(viewState, player, team)}
                        {this.renderAnswerView(viewState, player, team, players, catchphraseData.isMoneyDrop)}
                        {this.renderRevealAnswerSection(viewState, catchphraseData, teams)}
                        {this.renderRoundSummarySection(viewState, catchphraseData, team)}
                        {this.renderEndGameSection(viewState, player, team)}

                        {this.renderStartWarning()}
                    </div>
                    :
                    <Loading loadingText={"Connecting you to the game..."} noBg={false} hideLoader={false} />
            }

            {this.renderDebugButtons()}

        </>
    }

    //#region Debugging
    renderDebugButtons() {
        if (window.location.hostname !== "localhost") return;
        return (
            <div style={{ position: "absolute", zIndex: 999, pointerEvents: 'initial', fontSize: "10px" }}>
                <button onClick={() => this.setState({ connected: true })}>Connect</button>
                <button onClick={() => this.testingSetCatchphraseDataState({ gameState: GameStates.Loading })}>Loading</button>
                <button onClick={() => this.testingSetCatchphraseDataState({ gameState: GameStates.MainMenu })}>MainMenu</button>
                <button onClick={() => this.testingSetCatchphraseDataState({ gameState: GameStates.Tutorial })}>Tutorial</button>
                <button onClick={() => this.testingSetCatchphraseDataState({ gameState: GameStates.GameSelect })}>GameSelect</button>
                <button onClick={() => this.testingSetCatchphraseDataState({ gameState: GameStates.RoundIntro })}>RoundIntro</button>
                <button onClick={() => this.testingSetCatchphraseDataState({ gameState: GameStates.SelectingTeams })}>SelectingTeams</button>
                <button onClick={() => this.testingSetCatchphraseDataState({ gameState: GameStates.Playing })}>Playing</button>
                <button onClick={() => this.testingSetCatchphraseDataState({ gameState: GameStates.RevealAnswer })}>RevealAnswer</button>
                <button onClick={() => this.testingSetCatchphraseDataState({ gameState: GameStates.RoundSummary })}>RoundSummary</button>
                <button onClick={() => this.testingSetCatchphraseDataState({ gameState: GameStates.EndGame })}>EndGame</button>
                <button onClick={() => this.testingBuzzIn()}>TestBuzzIn</button>
            </div>
        )
    }

    testingSetCatchphraseDataState(state) {
        this.setState({ roomState: { ...this.state.roomState, catchphraseData: { ...this.state.roomState.catchphraseData, ...state } } });
    }

    testingBuzzIn() {
        const team = this.state.roomState.catchphraseData.teams.at(0);
        team.isBuzzedIn = !team.isBuzzedIn;
        const player = this.state.roomState.players.values().toArray()[0];
        player.catchphraseData.isBuzzedIn = !player.catchphraseData.isBuzzedIn;
        //this.setState({ roomState: { ...this.state.roomState } }, () => this.inputRef.current?.focus());
        //console.log(this.inputRef.current);
    }
    //#endregion
}
